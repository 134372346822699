import * as React from "react";
import Link from "next/link";
import {
	HamburgerIcon,
	CloseIcon,
	EnvelopeIcon,
	PhoneIcon,
	WhatsappIcon,
} from "icons/components";
import sx from "./header.module.css";
import { useIsActiveSubPath } from "./utils";
import { BaronLogoFlag } from "../baron-logo-flag";
import { resolveLink } from "../../utils/link";
import type {
	NavigationLink as NavigationLinkTypes,
	NavigationSection as NavigationSectionTypes,
	ContactInfo,
} from "@/types/generated/schema";
import { useLockBodyScroll } from "../../utils/use-lock-body-scroll";
import clsx from "clsx";

type HeaderProps = {
	title: string;
	mainNavigation?: NavigationSectionTypes;
	siteContactInfo?: ContactInfo;
};
export function Header({
	title,
	mainNavigation,
	siteContactInfo,
}: HeaderProps): JSX.Element {
	const [showNav, setShowNav] = React.useState(false);
	useLockBodyScroll(showNav);

	return (
		<div className={sx.root} data-show-nav={showNav}>
			<BaronLogoFlag title={title} />

			<nav className={sx.nav}>
				<ul className={sx.navItems}>
					{mainNavigation &&
						mainNavigation.links.map((item) => {
							return (
								<NavItem
									key={item._key}
									item={item}
									onClick={() => setShowNav(false)}
								/>
							);
						})}
				</ul>
				<button
					type="button"
					className={sx.showNavButton}
					onClick={() => setShowNav(!showNav)}
				>
					{showNav ? (
						<CloseIcon className={sx.hamburgerIcon} />
					) : (
						<HamburgerIcon className={sx.hamburgerIcon} />
					)}
				</button>
			</nav>
			<div
				className={clsx(
					sx.socialIcons,
					"absolute top-0 flex flex-row items-center justify-center ml-36 h-full md:right-0 md:ml-0 md:mr-4",
				)}
			>
				{siteContactInfo && (
					<>
						{siteContactInfo.email && (
							<a href={`mailto:${siteContactInfo.email}`} className="px-3 py-4">
								<EnvelopeIcon className="w-5 h-5" />
							</a>
						)}
						{siteContactInfo.phoneNumber && (
							<a
								href={`tel:${siteContactInfo.phoneNumber}`}
								className="px-3 py-4"
							>
								<PhoneIcon className="w-4 h-4" />
							</a>
						)}
						{siteContactInfo.whatsappLink && (
							<a
								href={siteContactInfo.whatsappLink}
								className="px-3 py-4"
								target="_blank"
								rel="noopener noreferrer"
							>
								<WhatsappIcon className="w-4 h-4" />
							</a>
						)}
					</>
				)}
			</div>
		</div>
	);
}

type NavItemProps = {
	item: NavigationLinkTypes;
	onClick: () => void;
};

function NavItem({ item, onClick }: NavItemProps): JSX.Element | null {
	// @ts-ignore
	const href = item.target !== null ? resolveLink(item.target) : undefined;
	// @ts-ignore
	const title = item.title || item.target?.title || "";

	const isActive = useIsActiveSubPath(href);

	return (
		<li
			className={clsx(
				sx.navItem,
				"group relative flex flex-col font-rigBold font-bold",
			)}
		>
			{href ? (
				<Link href={href}>
					<a
						data-is-active={isActive ? "true" : "false"}
						role="link"
						onClick={onClick}
						onKeyDown={onClick}
						tabIndex={0}
					>
						{title}
					</a>
				</Link>
			) : (
				<a>{title}</a>
			)}
			{item.children && (
				<div className={clsx(sx.submenu, "group-hover:flex")}>
					<ul className="px-8 h-auto bg-gray-500 rounded shadow md:top-0 md:px-0 md:w-48 md:bg-gray-900">
						{item.children.flatMap((submenuItem) => {
							if (!submenuItem.target) return [];

							return (
								<li
									key={submenuItem._key}
									className="flex text-right text-white border-b last:border-b-0 border-white md:text-left"
								>
									{/* @ts-ignore SUBMENU ITEM IS NOT A REFERENCE */}
									<Link href={resolveLink(submenuItem.target)}>
										<a
											className="px-4 py-2 w-full hover:text-primary text-sm"
											role="link"
											onClick={onClick}
											onKeyDown={onClick}
											tabIndex={0}
										>
											{submenuItem.title}
										</a>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</li>
	);
}
