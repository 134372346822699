import SanityClient from '@sanity/client';

const client = SanityClient({
  projectId: 'j2tu0oto',
  dataset: 'production',
  apiVersion: '2021-04-23',
  token: '', // or leave blank to be anonymous user
  useCdn: true, // `false` if you want to ensure fresh data
});

export default client;
