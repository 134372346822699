import Link from "next/link";
import EmbedHTML from "./EmbedHTML";
import Figure from "./Figure";
import { resolveBlockContentLink } from "@/utils/link";

const serializers = {
	types: {
		embedHTML: EmbedHTML,
		figure: Figure,
	},
	marks: {
		// eslint-disable-next-line react/display-name
		internalLink: ({ mark, children }) => {
			const href = resolveBlockContentLink(mark);

			if (!href) return null;

			return (
				<Link href={href}>
					<a className="hover:text-primaryDark font-bold">{children}</a>
				</Link>
			);
		},
		// eslint-disable-next-line react/display-name
		link: ({ mark, children }) => {
			const { blank, href } = mark;

			return blank ? (
				<a href={href} target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			) : (
				<a href={href}>{children}</a>
			);
		},
	},
};

export default serializers;
