import * as React from "react";

export function useLockBodyScroll(enabled: boolean): void {
	React.useEffect(() => {
		if (enabled) {
			document.body.classList.add("lock-scroll");
		} else {
			document.body.classList.remove("lock-scroll");
		}

		return () => document.body.classList.remove("lock-scroll");
	}, [enabled]);
}
