import * as React from "react";
import { useRouter } from "next/router";

export function useIsActiveSubPath(href: string): boolean {
	const router = useRouter();
	const [active, setActive] = React.useState(false);

	// HACK: determining active routes has to be done client-side because `ServerRouter` doesn't get the correct `asPath` when using rewrites
	// https://github.com/vercel/next.js/issues/17143#issuecomment-715409733
	// https://github.com/vercel/next.js/issues/22931
	React.useEffect(() => {
		const isActiveSubPath =
			href === "/" ? router.asPath === "/" : router.asPath.startsWith(href);
		setActive(isActiveSubPath);
	}, [href, router.asPath]);

	return active;
}
