import * as React from "react";
import Head from "next/head";
import { SanityImageAsset, SeoMeta } from "@/types/generated/schema";

type Props = {
	tags: SeoMeta;
};

export function Meta({ tags }: Props): JSX.Element {
	if (!tags) {
		return null;
	}

	// @ts-ignore
	const ogImage: SanityImageAsset = tags.openGraphImage
		? tags.openGraphImage.asset
		: undefined;

	return (
		<>
			<Head>
				{tags.title && <title key="title">{tags.title} | Uw Baron</title>}

				{tags.description && (
					<meta
						name="description"
						key="metaDescription"
						content={tags.description}
					/>
				)}

				{/* Begin OpenGraph Tag */}
				<meta property="og:type" key="ogType" content="website" />
				{tags.title && (
					<meta property="og:title" key="ogTitle" content={tags.title} />
				)}
				{tags.description && (
					<meta
						property="og:description"
						key="ogDescription"
						content={tags.description}
					/>
				)}

				{tags.metaTags && (
					<meta
						name="keywords"
						key="metaKeywords"
						content={tags.metaTags.join(", ")}
					/>
				)}

				{ogImage && (
					<>
						<meta property="og:image" key="ogImage" content={ogImage.url} />
						<meta
							property="og:image:type"
							key="ogImageType"
							content={ogImage.mimeType ?? "image/jpeg"}
						/>
						{ogImage.metadata?.dimensions.width && (
							<meta
								property="og:image:width"
								key="ogImageWidth"
								content={ogImage.metadata.dimensions.width.toString()}
							/>
						)}
						{ogImage.metadata?.dimensions.height && (
							<meta
								property="og:image:height"
								key="ogImageHeight"
								content={ogImage.metadata.dimensions.height.toString()}
							/>
						)}
						<meta property="og:image:alt" key="ogImageAlt" content="" />
					</>
				)}
				{ogImage && tags.title && (
					<meta
						property="og:image:title"
						key="ogImageTitle"
						content={tags.title ?? ""}
					/>
				)}

				{/* End of OpenGraph Tag */}
			</Head>
		</>
	);
}
